import { IonButton, IonButtons, IonCol, IonGrid, IonIcon, IonRow, IonText } from "@ionic/react"
import { Admin, Documents, Home, ImKChatLogo, KauelLogoTransparent, Logout, Menu, User } from "../../icons"
import { useEffect, useState } from "react"
import { useAuthContext } from "../../context/Auth.context"
import SelectFileModal from "../../modals/SelectFile.modal"
import AvatarOptionsModal from "../../modals/AvatarOptions.modal"
import { useHistory } from "react-router"
import { createOutline, documentTextOutline } from "ionicons/icons"
import { AvatarHistory } from "../../modals/AvatarHistory.modal"

export const NavbarContainer = () => {
    const {logout, isSmartPhone, logo, iconPage, userInvited, styleCompany, disableButtons, avatar} = useAuthContext()
    const [navBarExpanded, setNavBarExpanded] = useState(false)
    const [enableText, setEnableText] = useState(false)
    const [openSelectFileModal, setOpenSelectFileModal] = useState(false)
    const [openAvatarOptionModal, setOpenAvatarOptionModal] = useState(false)

    const [openAvatarHistoryModal, setOpenAvatarHistoryModal] = useState(false)

    const history = useHistory()

    useEffect(() => {
        window.addEventListener('click', (e: any) => {
            const navBar = document.getElementById('navBar')
            if (navBar && navBar.contains(e.target)){
              // Clicked in box
            } else{
              if (navBarExpanded) {
                expandMenu()
              }
            }
        });
    })

    useEffect(() => {
        const navBar = document.getElementById('navBar')
        if (navBarExpanded) {
            if (navBar) {
                navBar.style.width = '299px'
                navBar.style.transition = '1s'
                setTimeout(() => {
                    setEnableText(true)
                }, 600);
            }    
        } else {
            if (navBar) {
                setTimeout(() => {
                    setEnableText(false)
                }, 100);
                navBar.style.width = '108px'
                navBar.style.transition = '1s'
            } 
        }
    }, [navBarExpanded])

    useEffect(() => {
        if (isSmartPhone) {
            setEnableText(true)
        }
    }, [isSmartPhone])

    useEffect(() => {
        if (avatar) {
            console.log(avatar.historial)
        }
    }, [avatar])

    const openAvatarHistory = () => {
        setOpenAvatarHistoryModal(true)
    }

    const closeAvatarHistory = () => {
        setOpenAvatarHistoryModal(false)
    }

    const expandMenu = () => {
        setNavBarExpanded(navBarExpanded ? false : true)
    }

    return (
        <div className="navBar-container" id={'navBar-container'}>
            {avatar && <AvatarHistory
                /* history={avatar.historial} */
                isOpen={openAvatarHistoryModal}
                closeModal={closeAvatarHistory}
                avatar={avatar}
            />}
            {
                openSelectFileModal && <SelectFileModal
                    open={openSelectFileModal}
                    handleClose={() => {setOpenSelectFileModal(false)}}
                />
            }
            {openAvatarOptionModal && <AvatarOptionsModal
                open={openAvatarOptionModal}
                handleClose={() => {setOpenAvatarOptionModal(false)}}
            />}
            <button style={{backgroundColor: 'transparent'}} onClick={() => {expandMenu()}}>
                <IonGrid style={{padding: 0, height: 85}}>
                    <IonRow style={{padding: 0}}>
                        <div style={{textAlign: 'left', maxWidth: 140}}>
                            {
                                !enableText && <IonCol style={{padding: 0}}>
                                    <img width={'100%'} style={{maxWidth: 48}} src={iconPage}/>
                                </IonCol>
                            }
                            {
                                enableText && 
                                <IonCol style={{padding: 0}} size="auto">
                                    <img style={{maxWidth: 140}} src={logo} alt="" />
                                </IonCol>
                            }
                        </div>
                    </IonRow>
                </IonGrid>
            </button>
            <div style={{marginTop: 30, marginBottom: 30, textAlign: 'center', maxWidth: 48}}>
                <p style={{fontFamily:'Manrope'}}>Menu</p>
            </div>
            {userInvited && <IonButton fill="clear" className={`navBar-button icon-button-${styleCompany}`} onClick={() => {alert('Demo no permite creación de cuentas.')}}>
                <IonIcon icon={createOutline} style={{marginLeft: enableText ? 11.5 : 0, marginRight: enableText ? 11.5 : 0}} /> {enableText && <IonText style={{marginRight: 10}}>Create account</IonText>}
            </IonButton>}
            {!userInvited && <div>
                <IonButton style={{'--background-hover':'var(--k-eternal-button-second)', '--background-focused': 'var(--k-eternal-button-second)'}} fill="clear" slot="icon-only" className={`navBar-button icon-button-${styleCompany}`} onClick={() => {history.push('/home')}}>
                    <IonIcon style={{color:'var(--k-eternal-navbar-icon)', marginLeft: enableText ? 11.5 : 0, marginRight: enableText ? 11.5 : 0}} src={Home} /> {enableText && <IonText style={{marginRight: 10}}>Home</IonText>}
                </IonButton>
                <br />
                {/*<IonButton style={{'--background-hover':'var(--k-eternal-button-second)'}} disabled={disableButtons} fill="clear" slot="icon-only" className={`navBar-button icon-button-${styleCompany}`} onClick={() => {history.push('/admin')}}>
                    <IonIcon style={{color:'var(--k-eternal-navbar-icon)', marginLeft: enableText ? 11.5 : 0, marginRight: enableText ? 11.5 : 0}} src={Admin} /> {enableText && <IonText style={{marginRight: 10}}>Admin</IonText>}
                </IonButton>
                <br />*/}
                <IonButton style={{'--background-hover':'var(--k-eternal-button-second)'}} disabled={disableButtons} fill="clear" slot="icon-only" className={`navBar-button icon-button-${styleCompany}`} onClick={openAvatarHistory}>
                    <IonIcon style={{color:'var(--k-eternal-navbar-icon)', marginLeft: enableText ? 11.5 : 0, marginRight: enableText ? 11.5 : 0}} icon={documentTextOutline} /> {enableText && <IonText style={{marginRight: 10}}>Avatar history</IonText>}
                </IonButton>
            </div>}
            <div className="navBar-container" style={{position: "absolute", left: 0, bottom: 0}}>
                <IonButton style={{'--background-hover':'var(--k-eternal-button-second)'}} fill="clear" slot="icon-only" className={`navBar-button icon-button-${styleCompany}`} onClick={() => {logout()}}>
                    <IonIcon style={{marginLeft: enableText ? 11.5 : 0, marginRight: enableText ? 11.5 : 0}} src={Logout} /> {enableText && <IonText style={{marginRight: 10}}>Log out</IonText>}
                </IonButton>
            </div>
        </div>
    )
}