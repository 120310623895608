import { IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonInput, IonModal, IonTitle, IonToolbar } from "@ionic/react"
import { close } from "ionicons/icons"
import { CloudUpload } from "../../icons"

export const ManageAvatarModal = ({isOpen, setIsOpen, title}: any) => {
    return (
        <IonModal isOpen={isOpen}>
          <IonContent className="padding-45-5 relative-position">
            <button onClick={() => {setIsOpen(false)}} style={{position:'absolute', top: 10, right: 10, fontSize: 24, backgroundColor: 'transparent'}}>
                <IonIcon icon={close} />
            </button>
            <div style={{width: '100%'}}>
                <p style={{fontFamily: 'Poppins', fontSize:16}}>{title}</p>
            </div>
            <input placeholder="Name your k-eternal" className="input-modal" type="text" />
            <textarea name="" id=""></textarea>
          </IonContent>
        </IonModal>
    )
}