import { IonButton, IonButtons, IonCol, IonContent, IonGrid, IonIcon, IonInput, IonModal, IonRow, IonSpinner, IonTextarea } from "@ionic/react"
import { UploadIcon } from "../../icons"
import { useEffect, useState } from "react"
import { useAvatarContext } from "../../context/Avatar.context"
import { useHistory } from "react-router"
import { close } from "ionicons/icons"
import ExclamationModal from "../../modals/Exclamation.modal"

export const CreateAvatarComponent = () => {
    const {uploadImageToAvatar} = useAvatarContext()
    const [image, setImage] = useState<any>()
    const [openExclamationModal, setOpenExclamationModal] = useState(false)
    const [isOk, setIsOk] = useState(false)
    const [messageExclamationModal, setMessageEclamationModal] = useState('')
    const [nameAvatar, setNameAvatar] = useState('')

    const [responseAvatar, setResponseAvatar] = useState('')

    const [audioCloned, setAudioCloned] = useState<any>()

    const [waitCloneAudio, setWaitCloneAudio] = useState(false)

    const [isOpen, setIsOpen] = useState(false);

    const [enablePassToHome, setEnablePassToHome] = useState(false)

    const history = useHistory()

    useEffect(() => {
        const dragComponent = document.getElementById('upload-file-component')
        if (dragComponent) {
            dragComponent.addEventListener('dragover', () => {
                dragComponent.style.border = '2px red solid'
            })
            dragComponent.addEventListener('dragleave', () => {
                dragComponent.style.borderWidth = '0px'
            })
        }
    }, [])


    useEffect(() => {
        if (image) {
            console.log(image)
            const reader = new FileReader();
            reader.onload = loadedCallback;
            reader.readAsDataURL( image );
        }
    }, [image])

    useEffect(() => {
        if (audioCloned) {
            setWaitCloneAudio(false)
            setIsOpen(true)
        }
    }, [audioCloned])

    const loadedCallback = (e: any) => {
        const imageTag = document.getElementById('image') as HTMLImageElement
        if (imageTag) {
            imageTag.src = e.srcElement.result
            if (imageTag.height === imageTag.width) {
                null
            } else {
                setOpenExclamationModal(true)
                setMessageEclamationModal('Imagen no es cuadrada.')
                setIsOk(false)
            }
        }
    }

    const closeAudio = () => {
        setIsOpen(false)
        setEnablePassToHome(true)
    }

    const uploadImage = () => {
        const input = document.getElementById('image-input')
        if (input) {
            input.click()
        }
    }

    function hasWhiteSpace(s: string) {
        return s.indexOf(' ') >= 0;
    }

    const sendImageToServer = async () => {
        if (image) {
            console.log(image)
            const response = await uploadImageToAvatar(image)
            /* setWaitCloneAudio(true)
            const response = await uploadImageToAvatar(image)
            console.log(response)
            if (response) {
                const audioResponse = response.data.audio
                if (audioResponse) {
                    setAudioCloned(audioResponse)
                }
            } */
        } else {
            alert('No hay imagen')
        }
    }

    return (
        <IonContent style={{'--background':"#F6F6F6"}}>
            <ExclamationModal
                open={openExclamationModal}
                handleClose={() =>{setOpenExclamationModal(false)}}
                message={messageExclamationModal}
                isOk={isOk}
            />
            <div
                style={{position: 'absolute', display: isOpen ? 'block' : 'none', height: '100%', width: '100%', zIndex: 1000, backgroundColor: '#021111', opacity: 0.9}}
            >
                <IonButtons style={{position: 'absolute', top: 10, right: 10}}>
                    <IonButton onClick={closeAudio}>
                        <IonIcon icon={close} style={{color: 'white'}} />
                    </IonButton>
                </IonButtons>
                <IonGrid>
                    <IonRow>
                        <IonCol>
                        </IonCol>
                        <IonCol>
                            <div style={{marginTop: '50vh', width: '100%', opacity: 1, backgroundColor: 'transparent'}}>
                                <audio id={'audio-fixed'} style={{width: '100%'}} src={audioCloned} autoPlay controls />
                            </div>
                        </IonCol>
                        <IonCol>
                        </IonCol>
                    </IonRow>
                </IonGrid>
            </div>
            <IonGrid>
                <IonRow>
                    <IonCol>
                        
                    </IonCol>
                    <IonCol sizeXl="4" sizeLg="4" sizeMd="6" sizeSm="10" sizeXs="12">
                        <div
                            id={'upload-window'}
                            className="upload-window"
                        >
                            <input disabled={waitCloneAudio} id={'image-input'} style={{display: 'none'}} type="file" accept="image/jpeg" onChange={(e) => {(e.target.files && e.target.files[0]) ? setImage(e.target.files[0]) : alert('No file')}} />
                            <div style={{textAlign: 'center'}}>
                                <p style={{fontSize: 16}}>Crearemos tu avatar</p>
                            </div>
                            {
                                <div>
                                    <div id={'upload-file-component'} className="upload-file-component" onClick={uploadImage}>
                                        <img src={UploadIcon} style={{maxWidth: 70}} />
                                        <div style={{marginTop: 30}}>
                                            <p style={{fontSize: 16}}>
                                                {/* Drag & drop files or <a>Browse</a> */}
                                                Browse a file
                                            </p>
                                            <p style={{fontSize: 12, color: '#676767'}}>
                                                Supported formates: JPG
                                            </p>
                                            <p style={{fontSize: 12, color: '#676767'}}>
                                                Image must be square. Max size: 1080p x 1080p
                                            </p>
                                        </div>
                                    </div>
                                    {
                                        (image)
                                        ?
                                        <div style={{width: '100%', height: '30vh', textAlign: 'center', marginTop: 10}}>
                                            <div style={{position: 'relative', textAlign: 'center', marginRight: 'auto', marginLeft: 'auto', width: '50%', height: '100%', backgroundColor: '#ccc'}}>
                                                <div style={{
                                                    width: '100%',
                                                    textAlign: 'center',
                                                    position: 'absolute',
                                                    top: '50%',
                                                    msTransform: 'translateY(-50%)',
                                                    transform: 'translateY(-50%)'}}>
                                                        <img width={'100%'} style={{objectFit: 'cover'}} id={'image'} />
                                                </div>
                                            </div>
                                            
                                        </div>
                                        :
                                        <div style={{width: '100%', height: '30vh', marginTop: 10}}>
                                            <div style={{position: 'relative', textAlign: 'center', marginRight: 'auto', marginLeft: 'auto', width: '50%', height: '100%', backgroundColor: '#ccc'}}>
                                                <div style={{
                                                    width: '100%',
                                                    textAlign: 'center',
                                                    position: 'absolute',
                                                    top: '50%',
                                                    msTransform: 'translateY(-50%)',
                                                    transform: 'translateY(-50%)'}}>
                                                    <p
                                                        
                                                    >
                                                        Your image will be seen here
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    }
                                </div>
                            }
                            <br />
                            <div style={{textAlign: 'center'}}>
                                <IonRow>
                                {!enablePassToHome && 
                                <IonCol>
                                    <button
                                        disabled={waitCloneAudio} 
                                        onClick={sendImageToServer}
                                        style={{backgroundColor: '#333A4D', color: 'white', maxWidth: 200, fontSize: 18, padding: '0px 14px', borderRadius: 4, height: 34}}
                                        >
                                            <IonRow>
                                                {waitCloneAudio &&  
                                                <IonCol>
                                                    <IonSpinner style={{color: 'white'}} />
                                                </IonCol>}
                                                <IonCol>
                                                <p style={{margin: '6px 0px', marginLeft: 5}}>
                                                UPLOAD
                                                </p>
                                                </IonCol>
                                            </IonRow>
                                        </button>
                                </IonCol>
                                }
                                {
                                    (enablePassToHome || (localStorage.getItem('readyToHome'))) &&
                                        <IonCol>
                                            <button
                                            onClick={() => {localStorage.setItem('readyToHome', 'ok'); history.replace('/home/1723406552933')}}
                                            style={{backgroundColor: '#333A4D', color: 'white', maxWidth: 106, fontSize: 18, padding: '0px 14px', borderRadius: 4, height: 34}}
                                        >
                                            GO TO HOME
                                        </button>
                                        </IonCol>
                                }
                                </IonRow>
                                
                            
                                

                            </div>
                        </div>
                    </IonCol>
                    <IonCol>
                        
                    </IonCol>
                </IonRow>
            </IonGrid>
        </IonContent>
    )
}