import { createContext, useContext, useEffect, useState } from "react";
import { ManageAvatarModal } from "../general_components/ManageAvatar/ManageAvatar.modal";

export const TestComponentContext = createContext<any>({} as any)


export const TestComponentProvider = (props: any) => {

    const [openManageAvatar, setOpenManageAvatar] = useState(false)
    const [title, setTitle] = useState("Create your kindred eternal v2")
    
    const value = {
        setTitle,
        setOpenManageAvatar
    }

    return (
        <>
            <ManageAvatarModal
                isOpen={openManageAvatar}
                setIsOpen={setOpenManageAvatar}
                title={title}
            />
            <TestComponentContext.Provider value={value} >
                {props.children}
            </TestComponentContext.Provider>
        </>
    )
}

export const useTestComponentContext = () => useContext(TestComponentContext)