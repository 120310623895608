import { IonButton, IonButtons, IonCol, IonGrid, IonIcon, IonInput, IonModal, IonProgressBar, IonRow, IonSpinner, IonToolbar } from '@ionic/react'
import { Mic, Send } from '../../icons'
import { t } from 'i18next'
import { useEffect, useState } from 'react'
import { useAuthContext } from '../../context/Auth.context'
import { useMessagesContext } from '../../context/Messages.context'
import { MessageFormat } from '../../interfaces/Messages.interface'
import { chat, chatGPT } from '../../routes/ia.routes'
import { useFilesContext } from '../../context/Files.context'
import PDFModal from '../../modals/PDF.modal'
import { FilesData } from '../../interfaces/Files.interface'
import { stop, barcodeOutline } from 'ionicons/icons'
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import { useAvatarContext } from '../../context/Avatar.context'
import axios from 'axios'
import { useTestComponentContext } from '../../context/TestComponents.context'

const ChatContainer = () => {
    const {userId, initials, avatar, isSmartPhone, userInvited} = useAuthContext()
    const {messages, avatarWeb, setMessages} = useMessagesContext()
    const {fileSelected, setDisabledInput, setWaitWhileMessage} = useFilesContext()
    const {elevenLabsVoiceId, nameAvatar, setEnableAvatar, enableAvatar, avatarElement, setAvatarElement, optimized, setOptimized} = useAvatarContext()
    const [messagesToUse, setMessagesToUse] = useState<MessageFormat[]>([])
    const [waitResponse, setWaitResponse] = useState(false)
    const [avatarSpeaking, setAvatarSpeaking] = useState(false)
    const [audioBlob, setAudioBlob] = useState<Blob>()
    const [avatarPaused, setAvatarPaused] = useState(true)
    const {transcript,listening} = useSpeechRecognition()
    const [openFileReader, setOpenFileReader] = useState(false)
    const [message, setMessage] = useState('')
    const [audioMessage, setAudioMessage] = useState('')
    const [referenceSelected, setReferenceSelectec] = useState<{page_content: string, metadata: any}>()
    const [audio, setAudio] = useState<HTMLAudioElement>()
    const [messageDurationAudio, setMessageDurationAudio] = useState('')
    const [durationAudio, setDurationAudio] = useState(0)
    const [currentTimeAudio, setCurrentTimeAudio] = useState(0)
    const [enableAudioTime, setEnableAudioTime] = useState(false)
    const [progressAudio, setProgressAudio] = useState(0)

    const [videoWidth, setVideoWidth] = useState(0)

    const [openSheetModalInput, setOpenSheetModalInput] = useState(false)

    useEffect(() => {
        if (enableAvatar && avatar) {
            const element: HTMLVideoElement = document.getElementById('container-avatar') as HTMLVideoElement
            if (element) {
                console.log(element)
                setAvatarElement(element)
                setVideoWidth(element.offsetWidth)
            }
        } else {
            if (avatarElement && !enableAvatar) {
                avatarElement.pause()
                setAvatarElement(undefined)
            }
        }
    }, [enableAvatar, avatar])

    useEffect(() => {
        if (audio) {
            audio.onloadedmetadata = (e) => {
                setDurationAudio(Math.trunc(audio.duration))
            }
            audio.play();
            audio.addEventListener('playing', () => {
                setAvatarSpeaking(true)
                playSpeackAvatar()
            })
            audio.addEventListener('pause', () => {
                setAvatarSpeaking(false)
                stopSpeackAvatar()
            })
            audio.addEventListener('ended', () => {
                setAvatarSpeaking(false)
                stopSpeackAvatar()
            })
        }
    }, [audio])


    useEffect(() => {
        if (audioBlob) {
            console.log(audioBlob)
            const audioContext = new window.AudioContext;

            const reader = new FileReader();


            reader.onload = () => {
                const result: any = reader.result
                audioContext.decodeAudioData(result, (buffer) => {
                    console.log(buffer)
                    const source = audioContext.createBufferSource();
                    source.buffer = buffer;
                    
                    const analyser = audioContext.createAnalyser();
                    const dataArray = new Uint8Array(analyser.frequencyBinCount);
    
                    source.connect(analyser);
                    analyser.connect(audioContext.destination);
    
                    source.start(0);
    
                    checkVoice(analyser, dataArray)
    
                })
            }

            reader.readAsDataURL(audioBlob)
        }
    }, [audioBlob])

    const checkVoice = (analyser: any, dataArray: any)  => {
        analyser.getByteTimeDomainData(dataArray);
        const isSpeaking = dataArray.some((value: any) => value > 128);
        const statusElement = document.getElementById('status');
        if (statusElement)
        if (isSpeaking) {
            statusElement.classList.add('active');
        } else {
            statusElement.classList.remove('active');
        }        
    }

    useEffect(() => {
        if (durationAudio && audio) {
            if (audio) {
                let interval1: NodeJS.Timeout
                let interval2: NodeJS.Timeout
                audio.onplaying = (e) => {
                    let number = 0
                    let intervalNumber = 0
                    setEnableAudioTime(true)
                    interval1 = setInterval(() => {
                        number = number + 1
                        setCurrentTimeAudio(number)
                    }, 1000)
                    interval2 = setInterval(() => {
                        intervalNumber = intervalNumber + 1
                        const progress = (intervalNumber * 10) / durationAudio
                        setProgressAudio(progress * 0.01)
                    }, 100)
                }
                audio.onpause = () => {
                    setCurrentTimeAudio(0)
                    setEnableAudioTime(false)
                    setProgressAudio(0)
                    clearInterval(interval1)
                    clearInterval(interval2)
                }
            }
        }
    }, [durationAudio, audio])

    useEffect(() => {
        const timeCurrent = {
            minutes: '',
            seconds: ''
        }

        const timeDuration = {
            minutes: '',
            seconds: ''
        }

        const minutesCurrent = (Math.floor(currentTimeAudio / 60))
        const secondsCurrent = currentTimeAudio - (minutesCurrent * 60)

        timeCurrent.minutes = (minutesCurrent < 10) ? `0${minutesCurrent}` : `${minutesCurrent}`
        timeCurrent.seconds = (secondsCurrent < 10) ? `0${secondsCurrent}` : `${secondsCurrent}`

        const minutesDuration = Math.floor(durationAudio / 60)
        const secondsDuration = durationAudio - (minutesDuration * 60)

        timeDuration.minutes = (minutesDuration < 10) ? `0${minutesDuration}` : `${minutesDuration}`
        timeDuration.seconds = (secondsDuration < 10) ? `0${secondsDuration}` : `${secondsDuration}`

        setMessageDurationAudio(`${timeCurrent.minutes}:${timeCurrent.seconds} - ${timeDuration.minutes}:${timeDuration.seconds}`)
        
    }, [durationAudio, currentTimeAudio])

    useEffect(() => {
        if (avatarElement && avatar && avatar.urlIdle) {
            avatarElement.src = avatar.urlIdle
            if (avatarElement.src) {
                setTimeout(() => {
                    console.log('avatar play')
                    const playPromise = avatarElement.play()
                    if (playPromise !== undefined) {
                        playPromise.then(function() {
                          // Automatic playback started!
                        }).catch(function(error) {
                          console.log(error)
                        });
                    }
                }, 1000);
            }
        }
    }, [nameAvatar, avatar, avatarElement])

    const stopSpeackAvatar = () => {
        if (avatarElement) {
            avatarElement.pause()
            avatarElement.src = avatar.urlIdle
            setTimeout(() => {
                avatarElement.play()
                if (audio && audio.played) {
                    audio.pause()
                    setAudio(undefined)
                }
                setAvatarSpeaking(false)
            }, 100);
        }
    }

    const playSpeackAvatar = () => {
        if (avatarElement) {
            avatarElement.pause()
            avatarElement.src = avatar.urlSpeacker
            avatarElement.play()
            setAvatarSpeaking(true)
            setAvatarPaused(false)
        }
    }

    useEffect(() => {
        if (fileSelected) {
            setDisabledInput(fileSelected.wait)
            if (fileSelected && fileSelected.messages.length > 0) {
                const messagesToShowInChat: MessageFormat[] = []
                fileSelected.messages.forEach((message, i) => {
                    if (message.removedBy && message.removedBy.includes(userId)) {
                        console.log(message.removedBy.includes(userId))
                    } else {
                        messagesToShowInChat.push(message)
                    }
                    if (i === (fileSelected.messages.length - 1)) {
                        setMessagesToUse(messagesToShowInChat)
                    }
                })
            } else {
                setMessagesToUse([])
            }
        }
    }, [fileSelected])

    useEffect(() => {
        if (messagesToUse) {
            console.log(messagesToUse)
            if (messagesToUse.length > 0) {
                const el = document.getElementById('chatMain')
                if (el) {
                    el.scrollTop = el.scrollHeight
                }
            }
            const fileSelectedCache = fileSelected
            if (fileSelectedCache) {
                fileSelectedCache.messages = messagesToUse
            }
        }
    }, [messagesToUse])

    const messagesVisualization = (messages: MessageFormat[]) => {
        if (messages.length > 0) {
            setMessagesToUse(messages)
            messages.forEach((message,) => {
                if (!message.isNew) {
                    const elMessage1 = document.getElementById(`${message._id}`)
                    if (elMessage1) {
                        elMessage1.style.visibility = 'hidden'
                        elMessage1.style.opacity = '0'
                        setTimeout(() => {
                            elMessage1.style.visibility = 'visible'
                            elMessage1.style.opacity = '1'
                            elMessage1.style.transition = '500ms'
                            
                        }, 100);
                    }
                } else {
                    const el = document.getElementById('chatContainerData')
                    if (el) {
                        el.scrollTop = el.scrollHeight
                        const elMessage1 = document.getElementById(`${message._id}`)
                        if (elMessage1) {
                            elMessage1.style.visibility = 'hidden'
                            elMessage1.style.opacity = '0'
                            setTimeout(() => {
                                elMessage1.style.visibility = 'visible'
                                elMessage1.style.opacity = '1'
                                elMessage1.style.transition = '500ms'
                            }, 100);
                        }
                    }
                }
            })
        }
        if (messages.length === 0) {
            setMessagesToUse([])
        }
    }

    useEffect(() => {
        if (fileSelected) {
            const fileSelectedCache: FilesData = fileSelected
            fileSelectedCache.messages = [...messages]
        }
    }, [messages])

    const openDocumentReference = (el: {page_content: string, metadata: any}) => {
        if (fileSelected) {
            setOpenFileReader(true)
            setReferenceSelectec(el)
        }
    }

    const closeDocumentReference = () => {
        setOpenFileReader(false)
    }

    const newMessageData = (e: any) => {
        e.preventDefault();
        addMessage()
    }

    const initAudioMessage = async (url: string) => {
        if (audio) {
            audio.pause()
            setAudio(undefined)
        }
        setTimeout(async () => {
            const audioCache = new Audio(url);
            setAudio(audioCache)
        }, 100);
    }

    const addMessage = async () => {
        setWaitWhileMessage(true)
        if (message.length > 0) {
            const newMessage = {
                index: messages.length + 1,
                message: message,
                role: 'user',
                user: userInvited ? `${userInvited.name}` : initials,
                isNew: true,
                removedBy: []
            }
            const messagesCache = [...messages, newMessage]
            setMessages(messagesCache)
            setMessage('')
            setTimeout(() => {
                const el = document.getElementById('chatMain')
                if (el) {
                    el.scrollTop = el.scrollHeight
                    const elMessage1 = document.getElementById(`${newMessage.index}`)
                        if (elMessage1) {
                            elMessage1.style.visibility = 'hidden'
                            elMessage1.style.opacity = '0'
                            setTimeout(() => {
                                elMessage1.style.visibility = 'visible'
                                elMessage1.style.opacity = '1'
                                elMessage1.style.transition = '500ms'
                            }, 100);
                        }
                }
            }, 100)
            setWaitResponse(true)
            try {
                const response = await chatGPT(message, userId, avatar._id, avatar.voice)
                setWaitResponse(false)
                if (response.data) {
                    const newMessage2 = {
                        index: messagesCache.length + 1,
                        message: '',
                        role: 'assistant',
                        user: `${avatar.name} ${avatar.lastName}`,
                        reference: response.data.context,
                        isNew: true,
                        removedBy: [],
                        audio: response.urlAudio
                    }
                    const messagesCahe2 = [...messagesCache, newMessage2]
                    setMessages(messagesCahe2)
                    let textIndex = 0
                    const text : string = response.data.answer
                    if (newMessage2.audio) {
                        const audioCache = new Audio(newMessage2.audio);
                        setAudio(audioCache)
                    }
                        if (text) {
                            messagesCahe2[messagesCahe2.length - 1].message = text
                        }
                        setWaitWhileMessage(false)
                        const interval = setInterval(() => {
                            textIndex = textIndex + 1
                            const newText = text.substring(0, textIndex)
                            const el = document.getElementById('chatMain')
                            const paragraph = document.getElementById(`messageText_${newMessage2.index}`)
                            if (paragraph) {
                                paragraph!.innerHTML = ''
                                const textInner = document.createTextNode(newText)
                                paragraph?.appendChild(textInner)
                                if (el) {
                                    el.scrollTop = el.scrollHeight
                                }
                                if (textIndex === text.length) {
                                    clearInterval(interval)
                                }
                            }
                        }, 50)
                }
                
            } catch (error: any) {
                console.log('Error: ', error)
                setWaitResponse(false)
                
                const newMessage2 = {
                    index: messagesCache.length + 1,
                    message: '',
                    role: 'assistant',
                    user: `${avatar.name} ${avatar.lastName}`,
                    isNew: true,
                    removedBy: []
                }
                const messagesCahe2 = [...messagesCache, newMessage2]
                setMessages(messagesCahe2)
                let textIndex = 0
                const text : string = error.response ? (((error.response.data && !error.response.data.message) ? 'Error data!' : (error.response.data.message && error.response.data.message.includes('timeout')))
                ? 'Error timeot!'
                : ( (error.response.data.message.includes('connect ECONNREFUSED')||(error.response.data.message.includes('ETIMEDOUT'))) ? t('homePage:cantConnectServer') : t('homePage:initialText'))) : error.message
                    if (text) {
                        messagesCahe2[messagesCahe2.length - 1].message = text
                    }
                    if (avatarWeb) {
                        avatarWeb.addMessage(text)
                        avatarWeb.processMessages()
                    }
                    setWaitWhileMessage(false)
                    const interval = setInterval(() => {
                        textIndex = textIndex + 1
                        const newText = text.substring(0, textIndex)
                        const el = document.getElementById('chatContainerData')
                        const paragraph = document.getElementById(`messageText_${newMessage2.index}`)
                        paragraph!.innerHTML = ''
                        const textInner = document.createTextNode(newText)
                        paragraph?.appendChild(textInner)
                        if (el) {
                            el.scrollTop = el.scrollHeight
                        }
                        if (textIndex === text.length) {
                            clearInterval(interval)
                            setMessages(messagesCahe2)
                        }
                    }, 50)
                /* } */

                
            }
        }
    }

    useEffect(() => {
        if (transcript.length > 0) {
            setAudioMessage(transcript)
        }
    }, [transcript])

    useEffect(() => {
        if (!listening) {
            if (audioMessage.length > 0) {
                setMessage(audioMessage)
                setTimeout(() => {
                    addMessage()
                }, 500);
            }
        }
    }, [listening])

    const startAudioMessage = () => {
        setAudioMessage('')
        SpeechRecognition.startListening()
    }

    const stopAudioMessage = () => {
        SpeechRecognition.stopListening();
        setMessage(audioMessage)
    }

    const writeMessage = (e: any) => {
        setMessage(e.target.value)
    }

    return (
        <div className='homeContainer'>
            {(fileSelected && referenceSelected) && <PDFModal 
                open={openFileReader}
                closeModal={closeDocumentReference}
                pdfFile={fileSelected.url}
                loading={false}
                reference={referenceSelected}
            />}
            <IonGrid>
                <IonRow>
                    {enableAvatar && <IonCol sizeXl={'6'} sizeLg={'6'} sizeMd={'6'} sizeSm={'12'} sizeXs={'12'}>
                        <IonRow>
                            <IonCol sizeXl={'2'} sizeLg={'2'} sizeMd={'1'} sizeSm={'0'} sizeXs={'0'}/>
                            <IonCol sizeXl={'8'} sizeLg={'8'} sizeMd={'10'} sizeSm={'12'} sizeXs={'12'}>
                                <div className={'content-avatar'} style={{position: 'relative', width:'100%', borderWidth: 0, backgroundColor: 'transparent', textAlign: 'center'}}>
                                    {/* <div style={{position: 'absolute', height: '100%', width: videoWidth, borderLeft: '80px solid white', borderRight: '80px solid white', zIndex: 100}}>
                                    </div> */}

                                    <div style={{position: 'absolute', height: '100%', left: 0, width: isSmartPhone ? 100:  70, backgroundColor: 'white', zIndex: 102}}>

                                    </div>
                                    <div style={{position: 'absolute', height: '100%', right: 0, width: isSmartPhone ? 100:  70, backgroundColor: 'white', zIndex: 102}}>

                                    </div>
                                    <video poster={avatar ? avatar.urlImage : ''} loop={true} id={'container-avatar'}
                                        className='video-avatar' width={'100%'} muted/>

                                    {(enableAudioTime && !isSmartPhone) && <div style={{position: 'absolute', bottom: 0, right: 10, zIndex:104}}>
                                        <IonButtons style={{backgroundColor: '#ccc', marginBottom: 10}}>
                                            <IonButton style={{'--color': '#444'}} disabled={!avatarSpeaking} onClick={stopSpeackAvatar}>
                                                <IonIcon icon={stop} /> Detener Audio
                                            </IonButton>
                                        </IonButtons>
                                        <IonRow>
                                            <IonCol>
                                                <IonProgressBar
                                                    style={{marginTop: 5}}
                                                    value={
                                                        progressAudio
                                                    }
                                                />
                                            </IonCol>
                                            <IonCol>
                                                <p style={{margin: 0, textAlign: 'center', fontSize: 10}}>
                                                    {messageDurationAudio}
                                                </p>
                                            </IonCol>
                                        </IonRow>
                                    </div>}
                                    {(enableAudioTime && isSmartPhone) && <div style={{position: 'absolute', bottom: -10, right: 10, width: '100%', zIndex: 104}}>
                                        <IonRow>
                                            <IonCol></IonCol>
                                            <IonCol>
                                                <IonProgressBar
                                                    style={{marginTop: 5}}
                                                    value={
                                                        progressAudio
                                                    }
                                                />
                                            </IonCol>
                                            <IonCol>
                                                <p style={{margin: 0, textAlign: 'center', fontSize: 10}}>
                                                    {messageDurationAudio}
                                                </p>
                                            </IonCol>
                                        </IonRow>
                                    </div>}
                                    {
                                        (enableAudioTime && isSmartPhone) && <div style={{position: 'absolute', top: 10, right: 10, zIndex: 104}}>
                                            <IonButtons style={{backgroundColor: '#ccc', marginBottom: 10}}>
                                                <IonButton style={{'--color': '#444'}} disabled={!avatarSpeaking} onClick={stopSpeackAvatar}>
                                                    <IonIcon icon={stop} /> 
                                                </IonButton>
                                            </IonButtons>
                                        </div>
                                    }
                                </div>
                            </IonCol>
                            <IonCol sizeXl={'2'} sizeLg={'2'} sizeMd={'1'} sizeSm={'0'} sizeXs={'0'}/>
                        </IonRow>
                    </IonCol>}
                    <IonCol /* className='margin-top-10' */ sizeXl={enableAvatar ? '6' : '12'} sizeLg={enableAvatar ? '6' : '12'} sizeMd={enableAvatar ? '6' : '12'} sizeSm='12' sizeXs='12'>
                        <div id={'chatMain'} className={ `chatMain ${isSmartPhone ? (enableAvatar ? 'chatContainerMobileWithAvatar' : 'chatContainerMobileWithOutAvatar') : 'chatContainerHeight'}`}>
                            <div className={`chatContainer`}>
                                <div className='chatContainerData' id='chatContainerData'>
                                    {
                                        /* messagesToUse */messages.map((message, i) => {
                                            return (
                                                <div
                                                    key={i}
                                                    style={{
                                                        marginBottom: 20,
                                                        width: '100%',
                                                        position: 'relative'
                                                }}>
                                                    <div 
                                                        className={
                                                            message.role === 'assistant' ? 'chatBot-keternal-padding' : 'chatUser-keternal-padding'
                                                        }
                                                    >
                                                        <div style={{paddingLeft: 24.47, fontFamily: 'Montserrat'}}>
                                                            <p style={{margin: 0, color: '#8A898E'}}>{message.user}</p>
                                                        </div>
                                                        <div id={`${message.index}`} className={(message.role === 'assistant') ? 'chatBot-keternal' : 'chatUser-keternal'}>
                                                            <div style={{width:'100%'}}>
                                                                <p className='messageText' id={`messageText_${message.index}`}>
                                                                    {message.message}
                                                                </p>
                                                                {
                                                                    (message.role === 'assistant' && message.audio) && 
                                                                    <IonToolbar style={{'--background': 'transparent'}}>
                                                                        <IonButtons slot={'end'} >
                                                                            <IonButton slot='icon-only' onClick={() => {initAudioMessage(message.audio!)}}>
                                                                                <IonIcon icon={barcodeOutline} />
                                                                            </IonButton>
                                                                        </IonButtons>
                                                                    </IonToolbar>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                        })
                                    }
                                    {
                                        waitResponse
                                        &&
                                        <div style={{width: '100%', paddingLeft: 16}}>
                                            <IonSpinner name='dots' />
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </IonCol>
                </IonRow>
                <IonRow>
                    <div className='textContainer'>
                        <form onSubmit={newMessageData}>
                            <div className='textItem'>
                                {
                                    listening
                                    ?
                                    <div className='textItemInputs'>
                                        <p>
                                            {
                                                audioMessage.length === 0
                                                ?
                                                'Recording'
                                                :
                                                audioMessage
                                            }
                                        </p>
                                    </div>
                                    :
                                    <IonInput
                                    className='textItemInputs'
                                    onIonInput={writeMessage}
                                    value={message}
                                />}
                                <button
                                    style={{position: 'absolute', right: 20, backgroundColor: 'transparent', top: 20, height: 25, width: 25}}
                                    onClick={() => {(message.length === 0) && (!listening ? startAudioMessage() : stopAudioMessage())}}
                                    type={(message.length > 0) ? 'submit' : 'button'}
                                >
                                    <img src={message.length === 0 ? Mic : Send} alt="" />
                                </button>
                            </div>
                        </form>
                    </div>
                </IonRow>
                {/* {
                    isSmartPhone
                    &&
                    <div style={{position: 'absolute', bottom: 40, left: 0, width: '100%', padding: '0px 10px'}}>
                        <div className='textContainer'>
                            <div className='textItem' onClick={() => {setOpenSheetModalInput(true)}}>
                                {
                                    listening
                                    ?
                                    <div className='textItemInputs'>
                                        <p>
                                            {
                                                audioMessage.length === 0
                                                ?
                                                'Recording'
                                                :
                                                audioMessage
                                            }
                                        </p>
                                    </div>
                                    :
                                    <div className='textItemInputs' >
                                        <p>{message}</p>
                                    </div>}
                            </div>
                            <button
                                style={{position: 'absolute', right: 20, backgroundColor: 'transparent', top: 20, height: 25, width: 25, zIndex: 999}}
                                onClick={() => {(message.length === 0) && (!listening ? startAudioMessage() : stopAudioMessage())}}
                                type={(message.length > 0) ? 'submit' : 'button'}
                            >
                                <img src={message.length === 0 ? Mic : Send} alt="" />
                            </button>
                        </div>
                        <IonModal 
                            isOpen={openSheetModalInput}
                            onDidDismiss={() => {setOpenSheetModalInput(false)}}
                            initialBreakpoint={0.6}
                            breakpoints={[0.25, 0.5, 0.75]}
                            backdropBreakpoint={0.5}

                            >
                                <div style={{padding: '20px 10px 0px 10px'}}>
                                    <div className='textItem'>
                                        <div className='textItemInputs'>
                                            <IonInput
                                                className='textItemInputs'
                                                onIonInput={writeMessage}
                                                value={message}
                                            />
                                        </div>
                                    </div>
                                </div>
                        </IonModal>
                    </div>
                } */}
            </IonGrid>
        </div>
    )
}

export default ChatContainer