import { createContext, useContext, useEffect, useState } from "react";
import { MessageFormat } from "../interfaces/Messages.interface";
import { useFilesContext } from "./Files.context";
import { useAuthContext } from "./Auth.context";
import axios from "axios";
import { getChats } from "../routes/ia.routes";

interface MessagesType {
    messages: MessageFormat[]
    setMessages: React.Dispatch<React.SetStateAction<MessageFormat[]>>
    avatarWeb: any
    setAvatarWeb: React.Dispatch<any>
}

export const MessagesContext = createContext<MessagesType>({} as MessagesType)


export const MessagesProvider = (props: any) => {
    const {userId, avatar, user} = useAuthContext()
    const {fileSelected, updateFileSelected} = useFilesContext()
    const [messages, setMessages] = useState<MessageFormat[]>([])
    const [avatarWeb, setAvatarWeb] = useState<any>(null)

    useEffect(() => {
        if (fileSelected && fileSelected.messages.length > 0) {
            setMessages(fileSelected.messages)
        }
    },[fileSelected])

    useEffect(() => {
        console.log(messages)
        if (messages.length > 0 && fileSelected) {
            const fileSelectedCache = fileSelected
            fileSelectedCache.messages = messages
            /* updateFileSelected(fileSelectedCache) */
        }
    }, [messages])

    useEffect(() => {
        if (userId && avatar) {
            getAllMessages()
        }
    }, [userId, avatar])

    const getAllMessages = async () => {
        const response = await getChats(userId, avatar._id)
        console.log(response)
        const messagesFormat = response.messages.map((message: any, index: number) => {
            const newMessage : MessageFormat = {
                index: index,
                message: message.content,
                role: message.role,
                user: message.role === 'assistant' ? `${avatar.name} ${avatar.lastName}` : `${user?.name} ${user?.lastName}`,
                _id: message._id,
                audio: message.urlAudio
            }
            return newMessage
        })
        setMessages(messagesFormat)
    }

    const value = {
        messages,
        setMessages,
        avatarWeb,
        setAvatarWeb,
    }

    return (
        <MessagesContext.Provider value={value} >
            {props.children}
        </MessagesContext.Provider>
    )
}

export const useMessagesContext = () => useContext(MessagesContext)