import { IonButton, IonButtons, IonCol, IonContent, IonGrid, IonIcon, IonModal, IonRow, IonTextarea, IonTitle, IonToolbar } from "@ionic/react"
import { close } from "ionicons/icons"
import { Avatar } from "../interfaces/Avatar.interface"

export const AvatarHistory = ({isOpen, closeModal, avatar}: {isOpen: boolean, closeModal: () =>  void, avatar: Avatar}) => {
    console.log(history)
    return (
        <IonModal
            className="history-avatar"
            isOpen={isOpen}
            onDidDismiss={closeModal}
        >
            <IonContent class="ion-padding" >
                <IonToolbar>
                    <IonTitle>
                        La historia de {avatar.name} {avatar.lastName}
                    </IonTitle>
                    <IonButtons slot="end">
                        <IonButton onClick={closeModal}>
                            <IonIcon icon={close} />
                        </IonButton>
                    </IonButtons>
                </IonToolbar>
                <IonGrid>
                    <IonRow>
                        <IonCol>
                            
                        </IonCol>
                        <IonCol sizeXl="8" sizeLg="8" sizeMd="10" sizeSm="12" sizeXs="12">
                            <IonTextarea
                                style={{height: 'calc(100vh - 88px)'}}
                                value={avatar.historial ? avatar.historial : ''}
                            />
                        </IonCol>
                        <IonCol>

                        </IonCol>
                    </IonRow>
                </IonGrid>
            </IonContent>
        </IonModal>
    )
}