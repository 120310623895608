import { IonButton, IonButtons, IonContent, IonFabButton, IonIcon, IonItem, IonMenu, IonMenuToggle, IonPopover, IonToolbar, useIonLoading } from '@ionic/react'
import { Favorite, Info, Logout, Trash, UploadCloud, User } from '../../icons'
import { t } from 'i18next'
import { useAuthContext } from '../../context/Auth.context'
import { uploadFile } from '../../routes/ia.routes'
import { useMessagesContext } from '../../context/Messages.context'
import { MessageFormat } from '../../interfaces/Messages.interface'
import ExclamationModal from '../../modals/Exclamation.modal'
import { useState } from 'react'
import { useFilesContext } from '../../context/Files.context'
import { FilesData } from '../../interfaces/Files.interface'
import documentsIndexedDb from '../../indexedDb/documents.indexedDb'
import { NavbarContainer } from '../Navbar/Navbar.container'

const MenuComponent = () => {
    const {logout, userId, totalDocsByMonth, isPremium, avatar} = useAuthContext()
    const {messages, setMessages} = useMessagesContext()
    const {getFiles, files, setIdFile} = useFilesContext()
    const [openExclamation, setOpenExclamation] = useState(false)
    const [isOkExclamation, setIsOkExclamation] = useState(false)
    const [messageExclamation, setMessageExclamation] = useState('')
    const [present, dismiss] = useIonLoading();
    const [pdfFile, setPdfFile] = useState();
    const [numberPages, setNumberPages] = useState(0)
    const [loadPercent, setLoadPercent] = useState(0)
    const [messageLoading, setMessageLoading] = useState('')
    const [openLoading, setOpenLoading] = useState(false)
    const [openLoadingMessage, setOpenLoadingMessage] = useState(false)
    const [isTotal, setTotal] = useState(false)

    const goOut = () => {
        logout()
    }
    const uploadPdf = () => {
        if (isPremium || (totalDocsByMonth < 2)) {
            const elInput = document.getElementById('pdf-input')
            if (elInput) {
                elInput.click()
            }
        } else {
            console.log("Can't upload document. Upgrade your account.")
            setIsOkExclamation(false)
            setOpenExclamation(true)
            setMessageExclamation("Can't upload document. Upgrade your account.")
            setTimeout(() => {
                setOpenExclamation(false)
            }, 2000);
            setTimeout(() => {
                setMessageExclamation('')
            }, 3000);
        }
    }
    

    const progress = (e: any) => {
        console.log(e)
        setLoadPercent(e.progress * 100)
        setTotal((e.loaded === e.total) ? true : false)
    }
    const getFileToSend = async (e: any) => {
        console.log(e.target.files)
        if (e.target.files[0]) {
            uploadFileToServer(e.target.files)
        }
    }
    const uploadFileToServer = async (files: FileList | null, idFileNumber?: number) => {
        if (files) {
            console.log(files[0])
            present()
            let idFile : number
            let saveFile = true
            if (!idFileNumber) {
                idFile = Date.now()
            } else {
                idFile = idFileNumber
                saveFile = false
            }
            setIdFile(idFile)
            const response = await uploadFile(files[0], userId, idFile, progress)
            if (saveFile) {
                getBase64(files[0], idFile)
            }
            console.log(response)
            dismiss()
            const buttonEl = document.getElementById('menuButton')
            if (buttonEl) {
                buttonEl.click()
            }
            setOpenExclamation(true)
            setIsOkExclamation(true)
            setMessageExclamation('Your file has been sent')
            setTimeout(() => {
                setOpenExclamation(false)
            }, 2000);
            setTimeout(() => {
                setMessageExclamation('')
            }, 3000);
            setTimeout(() => {
                const newMessage : MessageFormat = {
                    index: messages.length + 1,
                    role: 'assistant',
                    user: `${avatar.name} ${avatar.lastName}`,
                    message: response.data.message,
                    isNew: true,
                    removedBy: []
                }
                const messagesCache = [...messages, newMessage]
                setMessages(messagesCache)
            }, 1000);
        }
    }
    const getBase64 = (file: any, idFile: number) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = async () => {
          console.log(reader.result)
          const fileToSave: FilesData = {
            id: idFile,
            file: reader.result?.toString() || '',
            name: file.name,
            userId: [userId],
            messages: [],
            url: '',
            wait: false,
            state: false
          }
          const resDb: any = await documentsIndexedDb.init()
          console.log(resDb)
          await documentsIndexedDb.update(fileToSave, resDb.database)
          getFiles()
        }
        reader.onerror = function (error) {
          console.log('Error: ', error)
        };
    }
    const closeExclamations = () => {
        setOpenExclamation(false)
    }
    const getFile = async (file: FilesData) => {
        if (confirm('Confirm that you want to change the document to process')) {
            console.log(file)
            const responseDoc = base64ToFile(file.file, file.name)
            const newDocument = new File([responseDoc], file.name)
            uploadFileToServer(createFileList(newDocument), file.id)
        }
    }
    const createFileList = (file: File) => {
        const list = new DataTransfer();
        list.items.add(file);
        const fileList = list.files;
        return fileList;
    }
    const base64ToFile = (base64File: string, name: string) => {
        console.log(base64File)
        const contentType = base64File.includes('pdf') ? 'pdf' : '';
        const sliceSize = 1024;
        const byteCharacters = atob(base64File.replace('data:application/pdf;base64,', ''));
        const bytesLength = byteCharacters.length;
        const slicesCount = Math.ceil(bytesLength / sliceSize);
        const byteArrays = new Array(slicesCount);
    
        for (let sliceIndex = 0; sliceIndex < slicesCount; ++sliceIndex) {
            const begin = sliceIndex * sliceSize;
            const end = Math.min(begin + sliceSize, bytesLength);
    
            const bytes = new Array(end - begin);
            for (let offset = begin, i = 0; offset < end; ++i, ++offset) {
                bytes[i] = byteCharacters[offset].charCodeAt(0);
            }
            byteArrays[sliceIndex] = new Uint8Array(bytes);
        }
        return new Blob(byteArrays, { type: contentType });
    }

    const removeFile = async (file: FilesData, index: number) => {
        if (confirm(`Confirm that you want to delete the document ${file.name}`)) {
            const db = await documentsIndexedDb.init()
            await documentsIndexedDb.deleteDb(file.id, db.database)
            getFiles()
        }
    }
    return (
        <IonMenu contentId='home-content' className='menu-list' menuId='left-menu'>
            <IonContent className='menuContent'>
                <NavbarContainer />
            </IonContent>
        </IonMenu>
    )
}

export default MenuComponent
